/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

export const onServiceWorkerUpdateReady = () => {
  // Change me to a react toast

  const answer = window.confirm(`Website has been updated. Reload page now?`);
  if (answer === true) {
    window.location.reload();
  }
};
