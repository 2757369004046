module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"white","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://kowalski7cc.xyz/"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":90,"maxWidth":1400,"linkImagesToOriginal":true,"withWebp":{"quality":90},"withAvif":{"quality":90},"srcSetBreakpoints":[200,296,351,401,520,696,890,936,1116,1296,1400],"loading":"lazy","decoding":"async","showCaptions":true,"markdownCaptions":false,"backgroundColor":"white","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kowalski7cc","short_name":"Kowalski7cc","description":"Kowalski7cc, solving problems by creating bigger ones","lang":"en","icon_options":{"purpose":"any"},"start_url":"./","background_color":"#292961","theme_color":"#292961","display":"minimal-ui","icon":"./src/assets/avatar.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"268fbfc4b297958281eb2184024884db"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
